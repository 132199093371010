<script>
export default {
    data() {
        return {
            windowWidth: window.innerWidth,
        };
    },
    mounted() {
        window.addEventListener("resize", () => {
            this.windowWidth = window.innerWidth;
        });
    },
    beforeDestroy() {
        window.removeEventListener("resize", () => {
            this.windowWidth = window.innerWidth;
        });
    },
    computed: {
        shouldShowImage() {
            return this.windowWidth < 1200;
        },
    },
};
</script>

<template>
    <article>
        <div class="hero-content">
            <div class="hero-text">
                <h1>Versiculo A Diario</h1>

                <img
                    v-if="shouldShowImage"
                    src="@/assets/hero.jpg"
                    alt="Biblia"
                />

                <div class="hero-verse">
                    <p class="text">
                        ¡Ojalá su corazón esté siempre dispuesto a temerme y a
                        cumplir todos mis mandamientos, para que a ellos y a sus
                        hijos siempre les vaya bien!
                    </p>

                    <p class="reference">Efesios 8:15-16</p>
                </div>
            </div>

            <img v-if="!shouldShowImage" src="@/assets/hero.jpg" alt="Biblia" />
        </div>
    </article>
</template>

<style scoped lang="scss">
article {
    display: flex;
    justify-content: center;
    background: #f0ead6;

    .hero-content {
        display: flex;
        justify-content: space-between;
        gap: 50px;
        padding: 80px 10%;
        width: 100%;
        max-width: 1200px;

        .hero-text {
            width: 50%;

            h1 {
                font-family: "Petrona", serif;
                font-weight: 700;
                font-size: 65px;
                color: #506351;
            }

            .hero-verse {
                border-left: solid 10px #506351;
                padding: 20px;
                display: flex;
                flex-direction: column;
                gap: 20px;

                .text {
                    font-family: "Alegreya Sans", sans-serif;
                    color: #506351;
                    font-size: 28px;
                }

                .reference {
                    font-family: "Alegreya Sans", sans-serif;
                    color: #7e8083;
                    font-size: 18px;
                }
            }
        }

        img {
            width: 50%;
            height: auto;
            border-radius: 20px;
        }
    }

    @media (max-width: 1200px) {
        .hero-content {
            .hero-text {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 25px;

                h1 {
                    text-align: center;
                }
            }

            img {
                width: 100%;
            }
        }
    }
}
</style>
