<template>
    <router-view />
</template>

<style lang="scss">
/* #app {
    height: 100%;
} */

* {
    margin: 0;
    padding: 0;
}

@font-face {
    src: url("@/assets/fonts/Petrona-SemiBold.ttf");
    font-family: "Petrona", serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
}

@font-face {
    src: url("@/assets/fonts/Petrona-MediumItalic.ttf");
    font-family: "Petrona", serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

@font-face {
    src: url("@/assets/fonts/AlegreyaSans-Regular.ttf");
    font-family: "Alegreya Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
}
</style>
