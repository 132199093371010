<script>
import NavBar from '../components/NavBar.vue';
import Hero from '../components/Hero.vue'
import GeneradorVersiculo from '../components/GeneradorVersiculo.vue'
import Footer from '../components/Footer.vue'


export default {
    components: {
        NavBar,
        Hero,
        GeneradorVersiculo,
        Footer
    }
};
</script>

<template>
    <NavBar />
    <Hero />
    <GeneradorVersiculo />
    <Footer />
</template>



<style lang="scss">

</style>
