<script>
import axios from "axios";

export default {
    data() {
        return {
            psalm: [],
            psalmBackground: "",
            psalmLoading: true,
            verse: [],
            verseBackground: "",
            verseLoading: true,
        };
    },
    methods: {
        async getPsalm() {
            this.psalmLoading = true;
            let pagePsalm = Math.floor(Math.random() * 10);
            let imgPsalm = Math.floor(Math.random() * 10);

            try {
                const response = await axios.get(
                    "https://versiculoadiario.000webhostapp.com/wp-json/custom/v2/random_psalms"
                    /* "http://localhost/versiculoadiario/index.php/wp-json/custom/v2/random_psalms/" */
                );
                this.psalm = response.data;
                console.log(this.psalm);
            } catch (error) {
                console.error("Error fetching posts:", error);
            } finally {
                this.psalmLoading = false;
            }

            try {
                const response = await axios.get(
                    `https://api.unsplash.com/search/photos?page=${pagePsalm}&query=landscape-photography&client_id=${process.env.VUE_APP_APIKEY}`
                );
                console.log(response.data.results[imgPsalm].urls.raw);
                this.psalmBackground = response.data.results[imgPsalm].urls.raw;
            } catch (error) {
                console.error("Error generating image:", error);
            }
        },
        async getVerse() {
            this.verseLoading = true;
            let pageVerse = Math.floor(Math.random() * 10);
            let imgVerse = Math.floor(Math.random() * 10);

            try {
                const response = await axios.get(
                    "https://versiculoadiario.000webhostapp.com/wp-json/custom/v2/random_verse"
                   /*  "http://localhost/versiculoadiario/index.php/wp-json/custom/v2/random_verse/" */
                );
                this.verse = response.data;
                console.log(this.verse);
            } catch (error) {
                console.error("Error fetching posts:", error);
            } finally {
                this.verseLoading = false; // Set isLoading to false after fetching data
            }

            try {
                const response = await axios.get(
                    `https://api.unsplash.com/search/photos?page=${pageVerse}&query=landscape-photography&client_id=${process.env.VUE_APP_APIKEY}`
                );
                console.log(response.data.results[pageVerse].urls.raw);
                this.verseBackground = response.data.results[imgVerse].urls.raw;
            } catch (error) {
                console.error("Error generating image:", error);
            }
        },
    },
    mounted() {
        this.getPsalm();
        this.getVerse();
    },
};
</script>

<template>
    <article>
        <div class="verses-content">
            <section>
                <div class="loader" v-if="this.psalmLoading"></div>
                <div v-if="!psalmLoading">
                    <h1>Salmo al Azar</h1>
                    <div
                        class="image-content"
                        :style="{
                            backgroundImage: 'url(' + psalmBackground + ')',
                        }"
                    >
                        <div class="image-text">
                            <p>{{ psalm[0].versetext }}</p>
                            <span
                                >{{ psalm[0].book_title }}
                                {{ psalm[0].chapter }}:{{
                                    psalm[0].verse
                                }}</span
                            >
                        </div>
                    </div>

                    <button @click="getPsalm">Nuevo Salmo</button>
                </div>
            </section>
                        <section>
                <div class="loader" v-if="this.verseLoading"></div>
                <div v-if="!this.verseLoading">
                    <h1>Versiculo al Azar</h1>
                    <div
                        class="image-content"
                        :style="{
                            backgroundImage: 'url(' + verseBackground + ')',
                        }"
                    >
                        <div class="image-text">
                            <p>{{ this.verse[0].versetext }}</p>
                        <span
                            >{{ this.verse[0].book_title }}
                            {{ this.verse[0].chapter }}:{{
                                this.verse[0].verse
                            }}</span
                        >
                        </div>
                    </div>
                    <button @click="getVerse">Nuevo Versiculo</button>
                </div>
            </section>
        </div>
    </article>
</template>

<style scoped lang="scss">
article {
    display: flex;
    justify-content: center;
    background: #7e8083;
    color: #f0ead6;

    .verses-content {
        display: flex;
        justify-content: space-between;
        gap: 50px;
        padding: 80px 10%;
        width: 100%;
        max-width: 1200px;

        section {
            max-width: 50%;

            .loader {
                margin: 0 auto;
                font-weight: bold;
                font-family: sans-serif;
                font-size: 30px;
                animation: l1 1s linear infinite alternate;
            }

            .loader:before {
                content: "Cargando...";
            }

            @keyframes l1 {
                to {
                    opacity: 0;
                }
            }

            div {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 25px;


                .image-content {
                    background-size: cover;
                    background-position: center;
                    padding: 5%;
                    border-radius: 20px;
                    height: 350px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;


                    .image-text {
                        padding: 5%;
                        border-radius: 20px;
                        background: #f0ead6;
                        opacity: 0.9;
                        color: #506351;
                    }
                }

                button {
                    cursor: pointer;
                    margin: 0 auto;
                    max-width: 150px;
                    border: none;
                    padding: 10px 20px;
                    background: #f0ead6;
                    color: #7e8083;
                    border-radius: 10px;
                    transition: transform 0.5s ease;

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }

    @media (max-width: 1200px) {
        .verses-content {
            flex-direction: column;
            section {
                /* height: 200px; */
                max-width: 100%;
            }
        }
    }
}
</style>
<!-- Saludad a Rufo, escogido en el Señor, y a su madre y mía.
romanos 16:13  -->
